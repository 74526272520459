import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack, useMediaQuery } from '@mui/material';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeButton from 'ui-component/ThemeButton';
import { API } from 'utils/api';

const Header = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account);
  const [chatLink, setChatLink] = useState('');
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    loadStatus();
  }, []);

  const loadStatus = async () => {
    try {
      const res = await API.get('/api/status');
      const { success, data } = res.data;
      if (success && data.chat_link) {
        localStorage.setItem('chat_link', data.chat_link);
        setChatLink(data.chat_link);
      } else {
        localStorage.removeItem('chat_link');
        setChatLink('');
      }
    } catch (error) {
      localStorage.removeItem('chat_link');
      setChatLink('');
    }
  };

  const NavButton = ({ to, children, chatLink }) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const commonButtonStyle = {
      transition: 'all 0.3s ease-in-out',
      textTransform: 'none',
      fontSize: isSmallScreen ? '0.8rem' : '0.875rem',
      borderRadius: '16px !important',
      border: 'none !important',
      background: 'linear-gradient(45deg, #5C6BC0 30%, #7986CB 90%)',
      color: '#fff',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: isSmallScreen ? '6px 12px' : '6px 16px',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        background: 'linear-gradient(45deg, #3949AB 30%, #5C6BC0 90%)',
        border: 'none !important'
      }
    };

    return (
      <Button
        component={Link}
        to={chatLink ? `${to}?chat_link=${encodeURIComponent(chatLink)}` : to}
        sx={{
          ...commonButtonStyle,
          background: pathname === to 
            ? 'linear-gradient(45deg, #26A69A 30%, #4DB6AC 90%)'
            : 'linear-gradient(45deg, #5C6BC0 30%, #7986CB 90%)',
          '&:hover': {
            ...commonButtonStyle['&:hover'],
            background: pathname === to
              ? 'linear-gradient(45deg, #00897B 30%, #26A69A 90%)'
              : 'linear-gradient(45deg, #3949AB 30%, #5C6BC0 90%)'
          }
        }}
      >
        {children}
      </Button>
    );
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: isExtraSmallScreen ? 'column' : 'row',
      alignItems: 'center',
      width: '100%',
      padding: isSmallScreen ? '2px' : '4px',
      marginTop: '-8px',
    }}>
      <Box
        sx={{
          width: isSmallScreen ? 'auto' : 228,
          marginBottom: isExtraSmallScreen ? '8px' : '0',
        }}
      >
        <LogoSection />
      </Box>

      <Stack 
        spacing={isSmallScreen ? 1 : 2} 
        direction={isExtraSmallScreen ? 'column' : 'row'} 
        alignItems="center"
        sx={{ 
          flexGrow: 1, 
          justifyContent: isExtraSmallScreen ? 'center' : 'flex-end',
          marginTop: isExtraSmallScreen ? '8px' : '0',
        }}
      >
        <ThemeButton />
        <NavButton to={pathname === '/home' ? '/about' : '/home'}>
          {pathname === '/home' ? '教程' : '首页'}
        </NavButton>
        {chatLink && <NavButton to="/chatweb" chatLink={chatLink}>对话</NavButton>}
        <Button
          component={Link}
          to="/login"
          sx={{
            transition: 'all 0.3s ease-in-out',
            textTransform: 'none',
            fontSize: isSmallScreen ? '0.8rem' : '0.875rem',
            borderRadius: '16px !important',
            border: 'none !important',
            background: 'linear-gradient(45deg, #FF7043 30%, #FF8A65 90%)',
            color: '#fff',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: isSmallScreen ? '6px 12px' : '6px 16px',
            whiteSpace: 'nowrap',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
              background: 'linear-gradient(45deg, #F4511E 30%, #FF7043 90%)',
              border: 'none !important'
            }
          }}
        >
          {account.user ? '控制台' : '登入'}
        </Button>
      </Stack>
    </Box>
  );
};

export default Header;