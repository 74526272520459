import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box,  Stack, IconButton, Tooltip, useTheme, styled } from '@mui/material';
import { API } from 'utils/api';
import { Link } from 'react-router-dom';

import ThemeButton from 'ui-component/ThemeButton';
import { marked } from 'marked';
import { showError, showNotice } from 'utils/common';
import Campaign from '@mui/icons-material/Campaign';

const commonNavButtonSx = {
  transition: 'all 0.3s ease-in-out',
  textTransform: 'none',
  fontSize: '0.875rem',
  borderRadius: '16px !important',
  border: 'none !important',
  background: 'linear-gradient(45deg, #5C6BC0 30%, #7986CB 90%)',
  color: '#fff',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6px 12px',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  minHeight: '32px',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    background: 'linear-gradient(45deg, #3949AB 30%, #5C6BC0 90%)',
    border: 'none !important'
  }
};

const NavButton = styled(Link)(({ theme }) => ({
  ...commonNavButtonSx,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));

const Home = () => {
  const theme = useTheme();

  const account = useSelector((state) => state.account);

  const displayNotice = async () => {
    try {
      const res = await API.get('/api/notice');
      const { success, message, data } = res.data;
      if (success && data) { 
        const htmlNotice = marked(data);
        showNotice(htmlNotice, true);
        // 更新最后显示时间
        localStorage.setItem('lastNoticeTime', Date.now().toString());
      } else if (!success) {
        showError(message || '加载公告失败');
      }
    } catch (error) {
      showError('无法加载公告: ' + (error.message || '未知错误'));
    }
  };

  const checkAndDisplayNotice = () => {
    if (account.user) {
      const lastNoticeTime = localStorage.getItem('lastNoticeTime');
      const currentTime = Date.now();
      const hoursPassed = (currentTime - parseInt(lastNoticeTime || '0')) / (1000 * 60 * 60);
    
      if (!lastNoticeTime || hoursPassed >= 24) {
        displayNotice();
      }
    }
  };
  

  useEffect(() => {
    checkAndDisplayNotice();
  }, [account.user]); // 添加 account 作为依赖项

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack spacing={2} direction="row" alignItems="center">
        <Tooltip title="公告">
          <IconButton 
            onClick={displayNotice} 
            color="primary"
            sx={{
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: theme.palette.primary.light
              }
            }}
          >
            <Campaign />
          </IconButton>
        </Tooltip>
        
        <ThemeButton />
        <NavButton 
          to="/chatweb"
          sx={{
            ...commonNavButtonSx,
            background: 'linear-gradient(45deg, #5C6BC0 30%, #7986CB 90%)',
            '&:hover': {
              ...commonNavButtonSx['&:hover'],
              background: 'linear-gradient(45deg, #3949AB 30%, #5C6BC0 90%)'
            }
          }}
        >
          对话
        </NavButton>
        <NavButton 
          to="/home"
          sx={{
            ...commonNavButtonSx,
            background: 'linear-gradient(45deg, #26A69A 30%, #4DB6AC 90%)',
            '&:hover': {
              ...commonNavButtonSx['&:hover'],
              background: 'linear-gradient(45deg, #00897B 30%, #26A69A 90%)'
            }
          }}
        >
          返回首页
        </NavButton>
      </Stack>
    </>
  );
};

export default Home;